























































































































































































































































































































.container {
	background-color: #f8f8f8;
	min-height: 100vh;
	font-family: PingFangSC-Regular;
	.content {
	}
}
.user_msg {
	background-color: #ffffff;
	margin: 10px;
	border-radius: 8px;
	// box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	.content-top {
		margin: 0 0.2rem;
		.all_cout {
			display: flex;
			padding: 0.24rem 0;
			.con_left {
				color: #5899f6;
				font-size: 0.3rem;
				font-weight: 500;
			}
			.con_right {
				color: #5899f6;
				font-size: 0.3rem;
				font-weight: 500;
				margin-left: 0.28rem;
			}
		}
	}
	.content {
		margin: 0 0.2rem;
		padding: 0.1rem 0 0.3rem 0;
		.list {
			display: flex;
			align-items: center;
			padding: 0.15rem 0;
			.con_left {
				font-family: PingFangSC-Medium;
				width: 24%;
				color: #333333;
				font-size: 0.28rem;
				font-weight: 550;
				text-align: left;
			}
			.con_left1 {
				font-family: PingFangSC-Medium;
				color: #5899f6;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: left;
			}
			.con_right {
				width: 76%;
				color: #6F7A8B;
				font-size: 0.28rem;
				margin-left: 0.2rem;
			}
			.con_right1 {
				color: #5899f6;
				font-size: 0.24rem;
			}
			.right-jingxin {
				color: #13ad8f;
			}
			.right-shenhe {
				color: #d98181;
			}
		}
		.list0 {
			display: flex;
			justify-content: space-between;
			border-bottom: 0.01rem solid rgba(211, 217, 222, 0.5);
			align-items: center;
			padding: 0.2rem 0;
			margin-bottom: 0.15rem;
			.con_left {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.28rem;
				font-weight: bold;
				text-align: left;
			}
			.con_right {
				color: #666666;
				font-size: 0.24rem;
				margin-left: 0.28rem;
			}
			.right-jingxin {
				color: #32BF88;
			}
			.right-shenhe {
				color: #d98181;
			}
		}
		.list3 {
			padding: 0.2rem 0;
			.con_left {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.3rem;
				font-weight: bold;
				text-align: left;
			}
		}
		.list4 {
			display: flex;
			align-items: center;
			padding-bottom: 0.2rem;
			border-bottom: 0.01rem solid rgba(211, 217, 222, 0.5);
			.con_left1 {
				font-family: PingFangSC-Medium;
				color: #6F7A8B;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: left;
			}
			.con_right1 {
				color: #6F7A8B;
				font-size: 0.24rem;
			}
		}
		.list5 {
			display: flex;
			align-items: center;
			padding: 0.24rem 0;
			justify-content: space-between;
			.dq {
				width: 50%;
				color: #6F7A8B;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: center;
			}
			.ls {
				width: 50%;
				margin-left: 0.46rem;
				color: #6F7A8B;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: center;
			}
			.active {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.28rem;
				font-weight: 550;
			}
		}
		.list6 {
			margin-top: 0.46rem;
			display: flex;
			justify-content: space-between;
			padding-bottom: 0.28rem;
			border-bottom: 0.01rem solid rgba(211, 217, 222, 0.5);
			.left_user {
				display: flex;
				flex-direction: column;
				align-items: center;
				.img {
					width: 1.2rem;
					height: 1.2rem;
					img {
						border-radius: 0.5rem;
						width: 100%;
						height: 100%;
					}
				}
				.name {
					margin-top: 0.08rem;
					color: #333333;
					font-weight: 500;
					font-size: 0.28rem;
				}
			}
			.msg-center {
				// margin-left: -0.3rem;
				margin-top: 0.07rem;
				.msg-item {
					margin-top: 0.2rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.mag-left {
						color: #333333;
						font-size: 0.28rem;
						font-weight: 500;
						text-align: left;
					}
					.msg-right {
						margin-left: 0.2rem;
						color: #6F7A8B;
						font-size: 0.28rem;
					}
				}
				.msg-item:first-child {
					margin-top: 0;
				}
				.msg-item:last-child {
					margin-top: 0.18rem;
				}
			}
			.msg-last {
				margin-top: 0.95rem;
				.msg-right-top {
					text-align: right;
					font-size: 0.24rem;
					font-weight: bold;
					color: #32BF88;
				}
				.msg-right-bottom {
					text-align: center;
					font-size: 0.24rem;
					font-weight: bold;
					color: #32BF88;
				}
			}
		}
		.list6:last-child {
			border-bottom: none;
		}
		.button {
			margin: 0 auto;
			margin-top: 0.3rem;
			width: 6.7rem;
			height: 0.8rem;
			background-color: #FAD538;
			color: #333333;
			line-height: 0.8rem;
			border-radius: 0.08rem;
			text-align: center;
			font-size: 0.32rem;
		}
	}
}
.bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	width: 100%;
	.button {
		margin: 0.19rem 0.2rem;
		padding: 0.22rem 2.82rem;
		background-color: #FAD538;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		border-radius: 0.08rem;
		font-weight: 500;
	}
}
.tan {
    width: 5.6rem;
    height: 3.6rem;
    // background-image: url(../../assets/imgs/tan_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 3.6rem;
    .tan_no_txt1 {
		padding-top: 0.69rem;
		padding-left: 0.2rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
        color: #333333;
        font-size: 0.24rem;
        color: #666666;
        text-align: center;
        .right {
            color: #FAD538;
        }
    }
    .tan_no_button {
        margin: 0 1.02rem;
        margin-top: 0.6rem;
        display: flex;
        justify-content: space-between;
        .yes {
            color: #EFBA0D;
            padding: 0.08rem 0.32rem;
            border: 1px solid #FAD538;
            border-radius: 0.08rem;
            background-color: #ffffff;
			font-size: 0.28rem;
        }
        .no {
            color: #ffffff;
            padding: 0.1rem 0.22rem 0.08rem 0.24rem;
            border-radius: 0.08rem;
            background-color: #FAD538;
        }
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;